import arTransactionDetails from '../../../../arReceivables/arTransactions/transaction/viewTransaction';
import tenantBillDetails from '../../leaseInvoices/tenantInvoices/tenantInvoiceDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '../../../../../utility/url.utility';
import Party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import addTenantBatchModal from './addTenantInvoice';
import advanceSearchModal from './advanceSearch/';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'TenantInvoice',
  components: {
    arTransactionDetails,
    addTenantBatchModal,
    advanceSearchModal,
    tenantBillDetails,
    DatePicker,
    Party
  },
  watch: {
    currentPage: function() {
      this.getTenantBatchList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTenantBatchList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: null,
      unsubscribe: null,
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      depVsetParam: null,
      showAdvanceSearchModal: false,
      showTenantBatchModal: false,
      payload: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      leasePrj: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      selectAllCheckBox: false,
      gridData: [],
      gridFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'schedule_status_vset_meaning',
          label: 'Schedule Status'
        },
        {
          key: 'lease_prj_name',
          label: 'Lease Project'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'customer_gst',
          label: 'Customer GST'
        },
        {
          key: 'amount'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'billing_period_from'
        },
        {
          key: 'billing_period_to'
        },
        {
          key: 'area_uom_vset_meaning',
          label: 'Area UOM'
        },
        {
          key: 'area'
        },
        {
          key: 'rate_uom_vset_meaning',
          label: 'Rate UOM'
        },
        {
          key: 'rate'
        },
        {
          key: 'transaction_currency',
          label: 'Currency'
        },
        {
          key: 'lease_master_agreement_no',
          label: 'MLA No.'
        },
        {
          key: 'lease_prj_unit_name',
          label: 'Lease Office'
        },
        {
          key: 'customer_id'
        },
        {
          key: 'customer_site_id'
        },
        {
          key: 'customer_site'
        },
        {
          key: 'customer_site_address'
        },
        {
          key: 'period_days'
        },
        {
          key: 'bill_days'
        },
        {
          key: 'act_bill_date_from'
        },
        {
          key: 'act_bill_date_to'
        },
        {
          key: 'discount_type_vset_meaning',
          label: 'Discount Type'
        },
        {
          key: 'discount_value',
          label: 'Discount'
        },
        {
          key: 'discount_reason_vset_meaning',
          label: 'Discount Reason'
        },
        {
          key: 'tax_cat_name',
          class: 'taxColomn'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'request_num'
        },
        {
          key: 'schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'document_num',
          label: 'Document Number'
        },
        {
          key: 'trx_number'
        },
        {
          key: 'error_msg'
        }
      ],
      searchParams: null,
      showPartyModal: false,
      gridIndex: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showCustomerSiteModal: false,
      billingPeriodDate: null,
      billingPeriodFromDate: null,
      billingPeriodToDate: null,
      showTenantBillModal: false,
      showArTransactionModal: false,
      billHdrId: null,
      trxHdrId: null,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      calculateAmt: 0,
      // clearGlDateArray: []
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.processInvoice();
        }
        if (actionName === 'add') {
          this.openCloseTenantBatchModal(true);
        }
        if (actionName === 'download' && !this.showTenantBatchModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseGenerateBatch/getTenantBatchList',
            'tenant invoice',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    mainSearch() {
      this.searchParams = null;
      this.getTenantBatchList();
    },
    getTenantBatchList() {
      if (this.searchParams) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        Object.assign(this.payload, this.searchParams);
      } else {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          lease_prj_id: this.leasePrj.value,
          bill_status_vset: this.status.value
        };
      }
      this.loader = true;
      this.$store
        .dispatch('leaseGenerateBatch/getTenantBatchList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page.map(data => {
              data.selectBox = false;
              data.final = true;
              data.discount_value = data.discount_value
                ? data.discount_value.toFixed(2)
                : null;
              data.amount = data.amount ? data.amount.toFixed(2) : null;
              data.rate = data.rate ? data.rate.toFixed(2) : null;
 
              if (!data.period_days) {
                const periodFrom = new Date(data.billing_period_from).setHours(
                  0,
                  0,
                  0,
                  0
                );
                const periodTo = new Date(data.billing_period_to).setHours(
                  0,
                  0,
                  0,
                  0
                );
                data.period_days =
                  Math.abs(periodFrom - periodTo) / (24 * 3600 * 1000) + 1;
              }
              return data;
            });
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeDate(date, index, column) {
      if (column === 'gl_date') {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          date,
          22,
          this.gridData[index].le_id,
          this.gridData[index].ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          if (isPeriodOpen === 'NOTDEFINED') {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Tenant Agreement: ${this.gridData[index].lease_tenant_agreement_no}, Input GL Date is Not Defined`;
            const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
              this.gridData[index].invoice_date,
              22,
              this.gridData[index].le_id,
              this.gridData[index].ou_id
            );
            validatePeriodDatePromise.then(isPeriodOpen => {
              this.gridData[index].gl_date =
                isPeriodOpen === 'NOTDEFINED'
                  ? null
                  : this.gridData[index].invoice_date;
              this.updateTenantInvoiceData(index);
            });
          } else {
            this.validateGlDate(index, date);
          }
        });
      } else {
        if (this.gridData[index].gl_date) {
          const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
            this.gridData[index].gl_date,
            date
          );
          if (checkGlDate) {
            this.gridData[index].invoice_date = date;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = `Lease Tenant Agreement: ${this.gridData[index].lease_tenant_agreement_no}, Please select Invoice date greater than GL date!`;
          }
        } else {
          this.gridData[index].invoice_date = date;
        }
        this.updateTenantInvoiceData(index);
      }
    },
    updateTenantInvoiceData(index) {
      const payload = {
        leaseTenantScheduleHdrId: this.gridData[index]
          .lease_tenant_schedule_dtl_id,
        data: {
          lease_tenant_schedule_details: [
            {
              customer_id: this.gridData[index].customer_id, //NotNull
              customer_site_id: this.gridData[index].customer_site_id, //NotNull
              discount_type_vset: this.gridData[index].discount_type_vset, //NotBlank
              discount_reason_vset: this.gridData[index].discount_reason_vset,
              discount_value: this.gridData[index].discount_value, //NotNull
              gl_date: this.gridData[index].gl_date, //NotBlank
              invoice_date: this.gridData[index].invoice_date, //NotBlank
              lease_tenant_schedule_dtl_id: this.gridData[index]
                .lease_tenant_schedule_dtl_id, //NotNull
              tax_cat_id: this.gridData[index].tax_cat_id, //NotNull
              bill_days: this.gridData[index].bill_days,
              period_days: this.gridData[index].period_days,
              act_bill_date_from: this.gridData[index].act_bill_date_from,
              act_bill_date_to: this.gridData[index].act_bill_date_to
            }
          ]
        }
      };
      this.loader = true;
      this.$store
        .dispatch('leaseGenerateBatch/updateInvoiceData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg='GL period is closed for the selected month.'
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    processInvoice() {
      const unProcessedInvoices = this.gridData.filter(data => data.selectBox);
      if (unProcessedInvoices && unProcessedInvoices.length > 0) {
        let allowedInvoices = [];
        allowedInvoices = unProcessedInvoices;
        const dataProcess = allowedInvoices.map(data => {
          return {
            final: data.final ? 'F' : 'D',
            id: data.lease_tenant_schedule_dtl_id
          };
        });
        const payload = {
          schd_id: dataProcess
        };
        this.loader = true;
        this.$store
          .dispatch('leaseGenerateBatch/processTenantInvoice', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    advanceSearch(flag) {
      this.showAdvanceSearchModal = flag;
    },
    advanceSearchData(searchParams) {
      this.legalEntity.value = searchParams.le_id ? searchParams.le_id : null;
      this.operatingUnit.value = searchParams.ou_id ? searchParams.ou_id : null;
      this.leasePrj.value = searchParams.lease_prj_id
        ? searchParams.lease_prj_id
        : null;
      this.searchParams = searchParams;
      this.showAdvanceSearchModal = false;
      this.getTenantBatchList();
    },
    openCloseTenantBatchModal(flag) {
      this.showTenantBatchModal = flag;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.gridIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = this.gridData[index].tax_group_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj.text = item.value_code;
        this.leasePrj.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (this.vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.gridData[this.gridIndex].discount_type_vset_meaning =
          item.value_meaning;
        this.gridData[this.gridIndex].discount_type_vset = item.value_code;
      } else if (this.vsetCode === 'DISCOUNT_REASON_VSET') {
        this.gridData[this.gridIndex].discount_reason_vset_meaning =
          item.value_meaning;
        this.gridData[this.gridIndex].discount_reason_vset = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.gridData[this.gridIndex].tax_cat_name = item.category_name;
        this.gridData[this.gridIndex].tax_cat_id = item.tax_cat_hdr_id;
      }
      this.updateTenantInvoiceData(this.gridIndex);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj.text = null;
        this.leasePrj.value = null;
      } else if (vsetCode === 'SCHEDULE_STATUS_VSET') {
        this.status.text = null;
        this.status.value = null;
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'TAX_CATEGORY_NAME_VSET') {
        this.gridData[index].tax_cat_name = null;
        this.gridData[index].tax_cat_id = null;
      } else if (vsetCode === 'DISCOUNT_TYPE_VSET') {
        this.gridData[index].discount_type_vset_meaning = null;
        this.gridData[index].discount_type_vset = null;
      }  else if (vsetCode === 'DISCOUNT_REASON_VSET') {
        this.gridData[index].discount_reason_vset_meaning = null;
        this.gridData[index].discount_reason_vset = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHidePartyModal(flag, index) {
      this.showPartyModal = flag;
      this.gridIndex = index;
    },
    getSelectedPartyCustomer(item) {
      this.gridData[this.gridIndex].customer_name = item.party_name;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.gridData[this.gridIndex].customer_id =
              response.data.data.customer_id;
            this.gridDataSite = response.data.data.customer_sites;
          }
        });
    },
    showHideCustomerSiteModal(flag, index) {
      if (flag) {
        this.showCustomerSiteModal = flag;
        this.gridIndex = index;
        const payload = {
          customerId: this.gridData[index].customer_id,
          lease: null
        };
        this.loader = false;
        this.$store
          .dispatch('party/getCustomerSiteById', payload)
          .then(response => {
            if (response.status === 200) {
              this.gridDataSite = response.data.data.customer_site_lists;
            }
          });
      } else {
        this.showCustomerSiteModal = false;
      }
    },
    selectedSite(item) {
      this.gridData[this.gridIndex].customer_site_id = item.customer_site_id;
      this.gridData[this.gridIndex].customer_site = item.site_name;
      this.gridData[this.gridIndex].customer_site_address =
        item.bill_to_address;
      this.showCustomerSiteModal = false;
    },
    calculateAmount() {
      let sum = 0;
      this.gridData.forEach(element => {
        if (element.selectBox) {
          if (element.discount_type_vset_meaning && element.discount_value) {
            if (
              element.discount_type_vset_meaning === 'ADHOC' &&
              element.discount_value
            ) {
              sum += parseFloat(element.amount - element.discount_value);
            } else if (
              element.discount_type_vset_meaning === 'PERCENTAGE' &&
              element.discount_value
            ) {
              sum += parseFloat(
                element.amount - (element.discount_value * element.amount) / 100
              );
            }
          } else {
            sum += parseFloat(element.amount);
          }
        }
        this.calculateAmt = parseFloat(sum).toFixed(2);
      });
    },
    checkUncheckAll() {
      this.gridData = this.gridData.map((data, index) => {
        if (data.request_num) {
          if (!/e/i.test(data.schedule_status_vset)) {
            data.selectBox = false;
          } else {
            data.selectBox = this.selectAllCheckBox;
          }
        } else {
          data.selectBox = this.selectAllCheckBox;
        }
        if (this.selectAllCheckBox) {
          this.selectBoxChecked(data.selectBox, index);
        }
        return data;
      });
      this.calculateAmount();
    },
    selectBoxChecked(flag, index) {
      if (this.gridData[index].schedule_status_vset !== 'P') {
        this.gridData[index].selectBox = flag;
        if (flag) {
          this.gridData[index].invoice_date = this.gridData[index].invoice_date
            ? this.gridData[index].invoice_date
            : this.gridData[index].billing_period_from;
          const glDatePromiseResult = commonHelper.getModuleBasedPeriodDate(
            22,
            this.gridData[index].le_id,
            this.gridData[index].ou_id
          );
          glDatePromiseResult.then(defaultOpenPeriodDate => {
            this.validateGlDate(index, defaultOpenPeriodDate);
          });
        }
      }
      this.calculateAmount();
    },
    validateCheckBox(row) {
      if (row.request_num) {
        if (!/e/i.test(row.schedule_status_vset)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validateGlDate(index, defaultOpenPeriodDate) {
      const checkGlDate = commonHelper.validateInvoiceAndPeriodDate(
        defaultOpenPeriodDate,
        this.gridData[index].invoice_date
      );
      if (checkGlDate) {
        this.gridData[index].gl_date = defaultOpenPeriodDate;
      } else {
        const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
          this.gridData[index].invoice_date,
          22,
          this.gridData[index].le_id,
          this.gridData[index].ou_id
        );
        validatePeriodDatePromise.then(isPeriodOpen => {
          this.gridData[index].gl_date =
            isPeriodOpen === 'NOTDEFINED'
              ? null
              : this.gridData[index].invoice_date;
        });
      }
      this.updateTenantInvoiceData(index);
    },
    disabledGlDates(date) {
      const restrictedDate = new Date(this.billingPeriodDate);
      restrictedDate.setHours(0, 0, 0, 0);
 
      return date < restrictedDate;
    },
    setReferenceDateForGl(row) {
      this.billingPeriodDate = row.billing_period_from;
    },
    setBillIndex(index) {
      this.getPeriodDaysCount(index);
    },
    getPeriodDaysCount(index) {
      const currentRow = this.gridData[index];
      let periodCount = 0;
      if (
        currentRow &&
        currentRow.act_bill_date_to &&
        currentRow.act_bill_date_from
      ) {
        const dateFrom = new Date(currentRow.act_bill_date_from).setHours(
          0,
          0,
          0,
          0
        );
        const dateTo = new Date(currentRow.act_bill_date_to).setHours(
          0,
          0,
          0,
          0
        );
        periodCount = Math.abs(dateFrom - dateTo) / (24 * 3600 * 1000) + 1;
        currentRow.bill_days = periodCount;
        this.getPerDayAmount(index);
        return currentRow.bill_days;
      } else {
        currentRow.bill_days = null;
      }
    },
    getPerDayAmount(index) {
      const currentRow = this.gridData[index];
      let amount = currentRow.amount / currentRow.period_days;
      const amountPerDay = amount;
      this.calcDiscount(index, amountPerDay);
    },
    calcDiscount(index, amountPerDay) {
      const currentRow = this.gridData[index];
      const discAmount =
        amountPerDay * (currentRow.period_days - currentRow.bill_days);
      currentRow.discount_value = Math.round(discAmount * 100) / 100;
      currentRow.discount_type_vset_meaning = 'ADHOC';
      currentRow.discount_type_vset = 'DIS_ADHOC';
    },
    disabledDates(date) {
      const restrictedFromDate = new Date(this.billingPeriodFromDate);
      restrictedFromDate.setHours(0, 0, 0, 0);
 
      const restrictedToDate = new Date(this.billingPeriodToDate);
      restrictedToDate.setHours(0, 0, 0, 0);
 
      return date < restrictedFromDate || date > restrictedToDate;
    },
    setReferenceDate(row) {
      this.billingPeriodFromDate = row.billing_period_from;
      this.billingPeriodToDate = row.billing_period_to;
    },
    showHideTenantBillModal(flag, billHdrId) {
      this.showTenantBillModal = flag;
      this.billHdrId = billHdrId;
    },
    showHideArTransactionModal(flag, trxHdrId) {
      this.showArTransactionModal = flag;
      this.trxHdrId = trxHdrId;
    },
    clearSearchFilters() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };
      this.leasePrj.value = null;
      this.leasePrj.text = null;
      this.status.value = null;
      this.status.text = null;
      this.searchParams = null;
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.calculateAmt = 0;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.$store.dispatch('leaseGenerateBatch/saveAdvSearchFilters', null);
  }
};