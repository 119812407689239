import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonhelper from '@/app/utility/mixin';
import { format } from 'date-fns';
export default {
  name: 'addTenantInvoice',
  components: {
    DatePicker,
    appStrings
  },
  mixins: [commonhelper],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      leaseProject: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      recoveryType: {
        value: null,
        text: null
      },
      billingPeriodUom: {
        value: null,
        text: null
      },
      billingPeriodFrom: format(new Date(), appStrings.DATEFNSFORMAT),
      billingPeriodTo: null,
      depVsetParam: null
    };
  },
  methods: {
    beforeToday(date) {
      if (this.startDate(date)) {
        return date;
      }
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.parent_value_set_id = valuseSetDtlId;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        });
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'UOM') {
        this.billingPeriodUom.text = item.value_meaning;
        this.billingPeriodUom.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leaseProject.text = item.value_code;
        this.leaseProject.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'LEASE SCHEDULE TYPE') {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (this.vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = item.value_meaning;
        this.recoveryType.value = item.value_code;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === 'UOM_CLASS') {
        this.billingPeriodUom.text = null;
        this.billingPeriodUom.value = null;
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.leaseProject.text = null;
        this.leaseProject.value = null;
      } else if (vsetCode === 'LEASE SCHEDULE TYPE') {
        this.scheduleType.text = null;
        this.scheduleType.value = null;
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      } else if (vsetCode === 'LEASE_RECOVERY') {
        this.recoveryType.text = null;
        this.recoveryType.value = null;
      }
    }
  }
};
