import DatePicker from 'vue2-datepicker';
// import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import masterLeaseAgreement from '../../../leases/masterAgreement';
import tenantLease from '../../../leases/tenantLease';
import party from '../../../../../generalLedger/party';
export default {
  name: 'AdvanceSearch',
  components: {
    DatePicker,
    masterLeaseAgreement,
    tenantLease,
    party
  },
  data() {
    return {
      componentName: null,
      showSubComponentModal: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      leasePrj: {
        value: null,
        text: null
      },
      selectedMla: {
        value: null,
        text: null
      },
      selectedTenantLease: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      recovery: {
        value: null,
        text: null
      },
      scheduleType: {
        value: null,
        text: null
      },
      customerName: {
        value: null,
        text: null
      },
      customerSite: {
        value: null,
        text: null
      },
      billingBatch: null,
      billingPeriodUom: {
        value: null,
        text: null
      },
      selectedTaxGroup: {
        text: null,
        value: null
      },
      selectedCategory: {
        text: null,
        value: null
      },
      billingPeriodFrom: [],
      billingPeriodTo: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      showCustomerSiteModal: false,
      glDate: null,
      invoiceDate: null,
      depVsetParam: null
    };
  },
  mounted() {
    const advFormData = this.$store.state.leaseGenerateBatch.advSearchFilters;
    if (advFormData) {
      this.setSearchItems(advFormData);
    }
  },
  methods: {
    selectedLeaseMaster(item) {
      this.selectedMla.text = item.lease_agreement_no;
      this.selectedMla.value = item.lease_master_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    selectedLeaseTenant(item) {
      this.selectedTenantLease.text = item.lease_tenant_agreement_no;
      this.selectedTenantLease.value = item.lease_tenant_agreement_hdr_id;
      this.showSubComponentModal = false;
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.parent_value_set_id = valuseSetDtlId;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = this.selectedTaxGroup.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        });
    },
    searchTenantBatchParams() {
      const searchParams = {
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        lease_prj_id: this.leasePrj.value,
        lease_master_agreement_hdr_id: this.selectedMla.value,
        lease_tenant_agreement_hdr_id: this.selectedTenantLease.value,
        customer_id: this.customerName.value,
        customer_site_id: this.customerSite.value,
        bill_status_vset: this.status.value,
        trx_cat_id: this.selectedCategory.value,
        billing_period_from: this.billingPeriodFrom
          ? commonHelper.formattedDate(this.billingPeriodFrom[0])
          : null,
        billing_period_to: this.billingPeriodFrom
          ? commonHelper.formattedDate(this.billingPeriodFrom[1])
          : null,
        invoice_date_from: this.invoiceDate ? this.invoiceDate[0] : null,
        invoice_date_to: this.invoiceDate ? this.invoiceDate[1] : null,
        gl_date_from: this.glDate ? this.glDate[0] : null,
        gl_date_to: this.glDate ? this.glDate[1] : null,
        recovery_type_vset: this.recovery.value,
        schedule_type_vset: this.scheduleType.value
      };
      this.$emit('advanceSearch', searchParams);
      this.$store.dispatch('leaseGenerateBatch/saveAdvSearchFilters', this);
      // this.$store.state.leaseGenerateBatch.leaseGenerateBatch.adv_search = this;
    },
    clearTenantBatchParams() {
      this.legalEntity.text = null;
      this.legalEntity.value = null;
      this.operatingUnit.text = null;
      this.operatingUnit.value = null;
      this.leasePrj.text = null;
      this.leasePrj.value = null;
      this.selectedMla.text = null;
      this.selectedMla.value = null;
      this.selectedTenantLease.text = null;
      this.selectedTenantLease.value = null;
      this.status.text = null;
      this.status.value = null;
      this.customerName.text = null;
      this.customerName.value = null;
      this.customerSite.text = null;
      this.customerSite.value = null;
      this.selectedTaxGroup.text = null;
      this.selectedTaxGroup.value = null;
      this.selectedCategory.text = null;
      this.selectedCategory.value = null;
      this.recovery.text = null;
      this.recovery.value = null;
      this.billingPeriodFrom = null;
      this.glDate = null;
      this.invoiceDate = null;
      this.scheduleType.text = null;
      this.scheduleType.value = null;
      this.$store.dispatch('leaseGenerateBatch/saveAdvSearchFilters', null);
      // this.$store.state.leaseGenerateBatch.leaseGenerateBatch.adv_search = null;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      } else if (this.vsetCode === 'UOM') {
        this.billingPeriodUom.text = item.value_meaning;
        this.billingPeriodUom.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj.text = item.lease_prj_name;
        this.leasePrj.value = item.lease_prj_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET
      ) {
        this.selectedTaxGroup.value = item.tax_group_id;
        this.selectedTaxGroup.text = item.tax_group;
        this.selectedCategory.text = null;
        this.selectedCategory.value = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.selectedCategory.text = item.category_name;
        this.selectedCategory.value = item.tax_cat_hdr_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      ) {
        this.status.text = item.value_meaning;
        this.status.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recovery.text = item.value_meaning;
        this.recovery.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_SCHEDULE_TYPE
      ) {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
      }
    },
    openCloseSubComponentModal(flag, componentName) {
      this.showSubComponentModal = flag;
      this.componentName = componentName;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    selectedPartyCustomer(item) {
      this.showSubComponentModal = false;
      this.customerName.text = item.party_name;
      this.getCustomerByPartyId(item.party_id);
      this.customerSite.text = null;
      this.customerSite.value = null;
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customerName.value = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.customerName.value,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customerName.text) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    selectedSite(item) {
      this.customerSite.text = item.site_name;
      this.customerSite.value = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    setDate(dateRange) {
      this.billingPeriodFrom = commonHelper.setFutureDate(dateRange);
    },
    setSearchItems(item) {
      this.legalEntity = item.legalEntity;
      this.operatingUnit = item.operatingUnit;
      this.leasePrj = item.leasePrj;
      this.selectedMla = item.selectedMla;
      this.selectedTenantLease = item.selectedTenantLease;
      this.status = item.status;
      this.customerName = item.customerName;
      this.customerSite = item.customerSite;
      this.selectedTaxGroup = item.selectedTaxGroup;
      this.selectedCategory = item.selectedCategory;
      this.billingPeriodFrom = item.billingPeriodFrom;
      this.glDate = item.glDate;
      this.invoiceDate = item.invoiceDate;
      this.recovery = item.recovery;
      this.scheduleType = item.scheduleType;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj.text = null;
        this.leasePrj.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET) {
        this.selectedTaxGroup.value = null;
        this.selectedTaxGroup.text = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET) {
        this.selectedCategory.text = null;
        this.selectedCategory.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET) {
        this.status.text = null;
        this.status.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_RECOVERY) {
        this.recovery.text = null;
        this.recovery.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_SCHEDULE_TYPE) {
        this.scheduleType.text = null;
        this.scheduleType.value = null;
      }
    }
  }
};
