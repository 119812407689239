import appStrings from '@/app/utility/string.utility.js';
export default {
  name: 'transactionAttribute',
  props: ['transactionDetails', 'trxHdrId'],
  data() {
    return {
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      attributeList:{},
    };
  },
  mounted() {
    if (this.trxHdrId) {
      this.getTransactionAttributeById();
    }
    if (this.transactionDetails.trx_status_code === 'INCOMPLETE') {
      this.editMode = true;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    getTransactionAttributeById() {
      this.loader = true;
      this.$store
        .dispatch('receivables/getTransactionAttributeById', this.trxHdrId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.attributeList = resp.data.data[0];
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    updateTrxnAttributes() {
        const payload = {
          trx_hdr_id: this.trxHdrId,
          attribute_1: this.attributeList.attribute_1,
          attribute_2: this.attributeList.attribute_2,
          attribute_3: this.attributeList.attribute_3,
          attribute_4: this.attributeList.attribute_4,
          attribute_5: this.attributeList.attribute_5,
          attribute_6: this.attributeList.attribute_6,
          attribute_7: this.attributeList.attribute_7,
          attribute_8: this.attributeList.attribute_8,
          attribute_9: this.attributeList.attribute_9,
          attribute_10: this.attributeList.attribute_10
        };
        this.loader = true;
        this.$store
          .dispatch('receivables/updateTrxnAttributes', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.getTransactionAttributeById();
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
  }
};
